import socket from '@/socket.js'
export default {
    data(){
        return {
            namespace: '',
            socket_clients: 0,
            comments_list: [],
            socket_connected: false,
        
        }
    },
    created(){
        
        socket.on("disconnect", () => {
            // console.log('socket disconcected')
            this.socket_connected = false

        });
		
            
    },
    methods: {
		destroy_socket() {
			socket.off("connect");
			socket.off("disconnect");
			socket.off("room clients");
			socket.off("new message");
			socket.off("someone said");
			socket.off("delete message");
			socket.disconnect();
		},
        socket_connect(){
			socket.on('room clients', (data) => {
				// console.log("users",data)
				this.socket_clients = data
			})
            socket.connect()
            socket.on("connect", () => {
            this.socket_connected = true
            setTimeout(() => {
                    console.log('socket connected')
                    let user = JSON.parse(localStorage.user).UserDisplayName 
                    if(this.namespace) socket.emit('join', {room: this.namespace, user: user})
                }, 1000);
			});

        },
        send_new_message(message){
            socket.emit('new message', message)
        },
        delete_new_message(message){
            // console.log(message)
            socket.emit('delete message', message)
        },
        alert_toaster(message,list){
            if (message.text){
                this.$toast(`User ${message.created_by_name} creted new message '${message.text}'`,"success")
            }
            else {
                // console.log("comment",typeof list)
                if(list.length) {
                    var a = list.filter(d => d.guid == message)
                    this.$toast(`User ${a[0].created_by_name} deleted message '${a[0].text}'`,"error") 
                }
            }
        }
    }
}

 
