
import { io } from "socket.io-client";

// const URL = "https://socket.tetris.eps.net";
const URL = process.env.NODE_ENV == 'development' 
		? window.location.origin.replace('8080', '3003') //"http://localhost:3003";
		: "https://socket.tetris.eps.net"

const socket = io(URL, { autoConnect: false });

export default socket;
